import React from 'react'
// import { Link } from "gatsby"

import ApplySingle from '../components/apply/single'
import Header from '../components/header'
import IntroAlt from '../components/sections/intro/alt'
import Layout from '../components/layout'
import HomeContent from '../components/sections/home-content'
import Partners from '../components/sections/partners'
import SEO from '../components/seo'

import single from '../images/single-insurance.jpg'

const FullAltPage = ({ location }) => (
  <Layout phone="877-755-6327" location={location}>
    <SEO
      title="Health Insurance Offers"
      keywords={[`medicare`, `health`, `insurance`, `plans`, `affordable`, `supplement`]}
    />
    <Header phone="877-755-6327" className="gray-style" grayLogo />
    <IntroAlt
      title="Find affordable health insurance now."
      subTitle="First, just answer a few questions for us so we can help you."
      colType="right-col"
      bgImage={single}
    >
      <ApplySingle className="input-borders full-width" />
    </IntroAlt>
    <main id="main">
      <Partners />
      <HomeContent />
    </main>
  </Layout>
)

export default FullAltPage
